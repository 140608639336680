'use strict';

(function() {
  class ViewJobStatusCtrl {
    constructor($uibModalInstance, options) {
      this.$uibModalInstance = $uibModalInstance;
      this.options = options;
      if (this.options.job) {
        this.job = angular.copy(this.options.job);
        if(this.job.importedEmails && this.job.importedEmails.length > 0) {
            this.importedEmails = this.job.importedEmails.join(", ");
        }
        if(this.job.alreadyExistEmails && this.job.alreadyExistEmails.length > 0) {
            this.alreadyExistEmails = this.job.alreadyExistEmails.join(", ");
        }
      }
    }
  }
  angular.module('windmanagerApp')
    .controller('ViewJobStatusCtrl', ViewJobStatusCtrl);
})();
